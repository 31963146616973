import React from 'react';
import { Spinner } from 'common';
import { Modal, Button, Stack, Group, Text } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { ShippingAddress } from 'store/shipping/types';
import styles from './Container.module.css';

interface Props {
  open: boolean;
  hideModal: () => void;
  createShippingLabels: () => void;
  isLoading: boolean;
  addressFrom: ShippingAddress;
  labName: string;
  numLabels: number;
}

const CreateShipmentModal = ({
  open,
  hideModal,
  createShippingLabels,
  isLoading,
  addressFrom,
  labName,
  numLabels,
}: Props) => {
  const language = useBroswerLanguage();

  return (
    <Modal
      size="xl"
      opened={open}
      onClose={hideModal}
      centered
      title={getString('createShippingLabels', language)}
    >
      <Stack>
        <Group>
          <Text fw={700}>{getString('numberOfLabels', language)}:</Text>
          <Text>{numLabels}</Text>
        </Group>
        <Group>
          <Text fw={700}>{getString('shippingFrom', language)}:</Text>
          <Text>
            {addressFrom.street}, {addressFrom.city} {addressFrom.zip} {addressFrom.state}
          </Text>
        </Group>
        <Group>
          <Text fw={700}>{getString('lab', language)}:</Text>
          <Text>{labName}</Text>
        </Group>
        <Group justify="flex-end">
          {isLoading && <Spinner data-test-id="spinner-shipment" className={styles.SmallSpinner} />}
          <Button
            data-test-id="create-shipping-label-modal"
            onClick={() => createShippingLabels()}
            className={styles.MarginRight}
          >
            {getString('create', language)}
          </Button>
          <Button color="darkRed" onClick={hideModal}>
            {getString('cancel', language)}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default CreateShipmentModal;
