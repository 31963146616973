export const PARENT_AGENCY = 'parent_agency';
export const AGENCY = 'agency';
export const LOCATION = 'location';

export const agencyDisplayNames = {
  [PARENT_AGENCY as string]: 'Parent Agency',
  [AGENCY as string]: 'Agency',
  [LOCATION as string]: 'Location',
};

export const BLUEPRINT = 'blueprint';
export const LAVORO = 'lavoro';
export const REDSTAR = 'redstar';
export const PATTERN = 'pattern';
export const PATTERN_AG = 'pattern ag';

export const regexPattern = new RegExp(PATTERN, 'gi');
export const regexPatternAg = new RegExp(PATTERN_AG, 'gi');

export const whiteLabels = [BLUEPRINT, LAVORO, REDSTAR];

export const DEALER_PORTAL_URL = 'https://www.pattern.ag/dealer-portal-24/home?pass=Dealer24';

export const SELF_SAMPLING = 'self_sampling';
export const SELF_SCANNING = 'self_scanning';
